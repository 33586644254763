// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconListCheck } from '@tabler/icons';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
// constant

// constant
const icons = { IconListCheck, MinorCrashIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const employeeDashboard = {
    id: 'employee-dashboard',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'employee',
            title: <FormattedMessage id="my-requests" />,
            type: 'item',
            url: '/employee',
            icon: icons.IconListCheck,
            breadcrumbs: false
        },
        {
            id: 'myfines',
            title: <FormattedMessage id="my-fines" />,
            type: 'item',
            url: '/myfines',
            icon: icons.MinorCrashIcon,
            breadcrumbs: false
        }
    ]
};

export default employeeDashboard;

/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create();

// eslint-disable-next-line import/no-mutable-exports
let baseUrl = '';
const getBaseUrl = async () => {
    if (baseUrl === '') {
        // eslint-disable-next-line no-else-return
        await fetch(`${process.env.PUBLIC_URL}/config.json`)
            .then((res) => res.json())
            .then((data) => {
                localStorage.setItem('url', data.url);
                localStorage.setItem('socket', data.socket);
                baseUrl = data.url;
            });
    }
    return baseUrl;
};
// interceptor for http
axiosServices?.interceptors.request.use(
    async (config) => {
        config.baseURL = await getBaseUrl();
        config.headers['Access-Control-Allow-Origin'] = await getBaseUrl();
        return config;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: false
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const preload = createSlice({
    name: 'preloader',
    initialState,
    reducers: {
        preloadStatus(state, action) {
            state.status = action.payload;
        }
    }
});

export default preload.reducer;

export const { preloadStatus } = preload.actions;

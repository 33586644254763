import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Dashboard page
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));

// vehicle information pages
const VehiclesList = Loadable(lazy(() => import('views/vehicle-information/VehiclesList')));

// assignment management pages
const AssignmentsList = Loadable(lazy(() => import('views/assignment-management/AssignmentsList')));
const AssignmentDetails = Loadable(lazy(() => import('views/assignment-management/AssignmentsList/AssignmentDetails')));

// assignment request pages
const AssignmentRequests = Loadable(lazy(() => import('views/assignment-requests')));
const Fines = Loadable(lazy(() => import('views/Fines')));

// super admin all requests page
const AllRequests = Loadable(lazy(() => import('views/superAdmin-dashboard')));
const RequestDetails = Loadable(lazy(() => import('views/superAdmin-dashboard/RequestDetails')));
const FinesDetails = Loadable(lazy(() => import('views/Fines/FinesDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/vehicle-information',
            element: <VehiclesList />
        },
        {
            path: '/vehicle-information/vehicles',
            element: <VehiclesList />
        },
        {
            path: '/assignment-management',
            element: <AssignmentsList />
        },
        {
            path: '/assignment-management/assignments',
            element: <AssignmentsList />
        },
        {
            path: '/assignment-management/assignments/assignment-details/:id',
            element: <AssignmentDetails />
        },
        {
            path: '/assignment-requests',
            element: <AssignmentRequests />
        },
        {
            path: '/fines',
            element: <Fines />
        },
        {
            path: '/fines/:id',
            element: <FinesDetails />
        },
        {
            path: '/all-requests',
            element: <AllRequests />
        },
        {
            path: '/all-requests/request-details/:id',
            element: <RequestDetails />
        }
    ]
};

export default MainRoutes;

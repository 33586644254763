import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import axios from '../../../../utils/axios';
// material-ui
import { styled } from '@mui/material/styles';
import { Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';

// assets
import { dispatch, useDispatch } from 'store';
import { notificationRefreshFunc, readNotification, getNotifications } from 'store/slices/notification';
import useConfig from 'hooks/useConfig';
import { FormattedMessage } from 'react-intl';
import { openSnackbar } from 'store/slices/snackbar';

// styles
const ListItemWrapper = styled('div')(({ theme, newitem }) => ({
    cursor: 'pointer',
    padding: 10,
    backgroundColor: newitem === 0 ? theme.palette.secondary[100] : 'white',
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ items, setOpen }) => {
    const { user } = useAuth();
    const dispatchFun = useDispatch();
    const navigate = useNavigate();
    const { locale } = useConfig();

    const handleReadNotification = async (item) => {
        if (item?.hasRead === false) {
            await dispatch(readNotification(item?.id, item?.employeeId));
            await dispatch(getNotifications(item?.employeeId));
        }
        if (
            item.type === 'request' &&
            (user.role?.includes('employee') || user.role?.includes('manager') || user.role?.includes('departmentManager'))
        ) {
            if (user.role?.includes('manager') || user.role?.includes('departmentManager')) {
                const isApproved = await axios.get(`/requests/${item?.target}`);
                if (isApproved.data.status === 'pending') {
                    navigate(`/manager/incoming-requests#${item?.target}`);
                } else {
                    navigate(`/employee/request-details/${item?.target}`, { state: { id: item?.target } });
                }
            } else {
                navigate(`/employee/request-details/${item?.target}`, { state: { id: item?.target } });
            }
        } else if (item.type === 'request') {
            const hasVehicle = await axios.get(`/requests/${item.target}`);
            if (hasVehicle.data.status === 'approved') {
                navigate(`/assignment-requests#${item?.target}`);
            } else if (hasVehicle.data.assignmentId != null) {
                navigate(`/assignment-management/assignments/assignment-details/${hasVehicle.data.assignmentId}`, {
                    state: { id: item?.target }
                });
            } else if (hasVehicle.data.status === 'employeeCanceled') {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="statusMessage-employeeCanceled" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            } else if (hasVehicle.data.status === 'adminCanceled') {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="statusMessage-adminCanceled" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            }
        }
        if (item.type === 'assignment') {
            if (user?.role?.includes('admin') || user?.role?.includes('superAdmin')) {
                navigate('/assignment-requests');
            } else {
                navigate(`/employee/request-details/${item?.target}`);
            }
        }
        if (item.type === 'vehicle') navigate('/vehicle-information/vehicles', { state: { id: item?.target } });
        if (item.type === 'fine') navigate(`/fines/${item?.target}`, { state: { id: item?.target } });

        setOpen(false);
        dispatchFun(notificationRefreshFunc);
    };

    // const handleNotificationDate = (str) => {
    //     let subStr;
    //     let newSubStr;
    //     const start = str.indexOf('[');
    //     const end = str.indexOf(']');
    //     if (start > 0 && end > 0) {
    //         subStr = str.substr(start, end - 1);
    //         const subStr1 = subStr.replace('[', '');
    //         const subStr2 = subStr1.replace(']', '');
    //         newSubStr = getFormatedDateAndTime(subStr2);
    //     }
    //     const newStr = str.replace(subStr, newSubStr);
    //     return newStr;
    // };

    return (
        <List sx={{ py: 0 }}>
            {items?.map((item, index) => (
                <Fragment key={index}>
                    <ListItemWrapper newitem={item.hasRead ? 1 : 0} onClick={() => handleReadNotification(item)}>
                        <ListItem alignItems="center">
                            <ListItemText primary={(locale === 'ar' ? item?.arabicTitle : item?.englishTitle) || ''} />
                        </ListItem>
                        <Grid container direction="column" className="list-container">
                            <Grid item xs={12}>
                                <Typography variant="h6" pl={2}>
                                    {locale === 'ar' ? item.arabicDetails : item.englishDetails}
                                    {/* {locale === 'ar'
                                        ? handleNotificationDate(item.arabicDetails)
                                        : handleNotificationDate(item.englishDetails)} */}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemWrapper>
                    <Divider />
                </Fragment>
            ))}
        </List>
    );
};

export default NotificationList;

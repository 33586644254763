// third-party
import { createSlice } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import { openSnackbar } from 'store/slices/snackbar';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    vehicles: [],
    count: 0,
    loading: false
};

const slice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET VEHICLES
        getVehiclesSuccess(state, action) {
            state.vehicles = action.payload;
        },

        getVehiclesCountSuccess(state, action) {
            state.count = action.payload;
        },

        changeLoading(state, action) {
            state.loading = action.payload;
        },

        // MODIFY VEHICLE
        modifyVehicleSuccess(state, action) {
            state.vehicles = [...state.vehicles, action.payload];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function isVehicleExists(plateNumber) {
    return async () => {
        const response = await axios.get(`/vehicles/plateNumber/${plateNumber}`);
        return response.data;
    };
}

export function getVehicles(filters) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            const response = await axios.get(`/vehicles/filter?filters=${filters}`);
            dispatch(slice.actions.getVehiclesSuccess(response.data.vehicles));
            dispatch(slice.actions.getVehiclesCountSuccess(response.data.count));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function modifyVehicle(vehicle, isNewVehicle, vehicleId) {
    dispatch(slice.actions.changeLoading(true));

    return async () => {
        try {
            if (isNewVehicle) {
                const response = await axios.post('/vehicles', vehicle);
                dispatch(slice.actions.modifyVehicleSuccess(response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="successful-vehicle-add" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            } else {
                const response = await axios.put(`/vehicles/${vehicleId}`, vehicle);
                dispatch(slice.actions.modifyVehicleSuccess(response.data));

                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="successful-vehicle-edit" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            dispatch(
                openSnackbar({
                    open: true,
                    message: <FormattedMessage id="fail-message" />,
                    variant: 'alert',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    alert: {
                        color: 'error',
                        severity: 'error'
                    }
                })
            );
        }
    };
}

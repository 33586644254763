import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH, EMPLOYEE_DASHBOARD_PATH, MANAGER_DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();
    const prevLocation = useLocation();

    useEffect(() => {
        if (isLoggedIn) {
            if (user?.role?.includes('admin') || user?.role?.includes('superAdmin') || user?.role?.includes('viewer'))
                navigate(DASHBOARD_PATH, { replace: true });
            else if (user?.role?.includes('manager') || user?.role?.includes('departmentManager')) {
                if (prevLocation?.state !== null) navigate(prevLocation?.state?.from, { replace: true });
                else navigate(MANAGER_DASHBOARD_PATH, { replace: true });
            } else navigate(EMPLOYEE_DASHBOARD_PATH, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Dashboard page
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));

// super admin all requests page
const AllRequests = Loadable(lazy(() => import('views/superAdmin-dashboard')));
const RequestDetails = Loadable(lazy(() => import('views/superAdmin-dashboard/RequestDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/all-requests',
            element: <AllRequests />
        },
        {
            path: '/all-requests/request-details/:id',
            element: <RequestDetails />
        }
    ]
};

export default MainRoutes;

// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';
import { FormattedMessage } from 'react-intl';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    fines: [],
    fineRequests: [],
    fine: null,
    loading: false,
    count: 0,
    update: 0
};

const slice = createSlice({
    name: 'fine',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Fines
        getFinesSuccess(state, action) {
            state.fines = action.payload;
        },

        getFinesCountSuccess(state, action) {
            state.count = action.payload;
        },

        changeLoading(state, action) {
            state.loading = action.payload;
        },

        // GET Fine
        getFineSuccess(state, action) {
            state.fine = action.payload;
        },

        // GET Fine Requests
        getFineRequestsSuccess(state, action) {
            state.fineRequests = action.payload;
        },

        // MODIFY Fine
        modifyFineSuccess(state, action) {
            state.fines = [...state.fines, action.payload];
        },
        update(state) {
            state.update += 1;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function isFineExists(fineNumber) {
    if (fineNumber === undefined) return;
    return async () => {
        const response = await axios.get(`/fines/fineNumber/${fineNumber}`);
        return response.data;
    };
}

export function getFines(filters) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            const response = await axios.get(`/fines?filters=${filters}`);
            dispatch(slice.actions.getFinesSuccess(response.data.fines));
            dispatch(slice.actions.getFinesCountSuccess(response.data.count));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFine(id) {
    return async () => {
        try {
            const response = await axios.get(`/fines/${id}`);
            dispatch(slice.actions.getFineSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFineRequests() {
    return async () => {
        try {
            const response = await axios.get('/requests/status/approved');
            dispatch(slice.actions.getFineRequestsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function modifyFine(fine, isNewFine, fineId) {
    let response;
    return async () => {
        try {
            if (isNewFine) {
                response = await axios.post('/fines', fine);
                // dispatch(slice.actions.modifyFineSuccess(response.data));
                dispatch(slice.actions.update());
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="successful-fine-add" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            } else {
                response = await axios.put(`/fines/${fineId}`, fine);
                dispatch(slice.actions.modifyFineSuccess(response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="successful-fine-edit" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            }
            if (response.status === 200) return 'success';
            return 'error';
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            if (isNewFine) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="fail-message" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        alert: {
                            color: 'error',
                            severity: 'error'
                        }
                    })
                );
            }
            return 'error';
        }
    };
}

export function manualFine(fine) {
    let response;
    return async () => {
        try {
            response = await axios.post('/fines/manual', fine);
            // dispatch(slice.actions.modifyFineSuccess(response.data));
            dispatch(slice.actions.update());
            dispatch(
                openSnackbar({
                    open: true,
                    message: <FormattedMessage id="successful-fine-add" />,
                    variant: 'alert',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' }
                })
            );
            if (response.status === 204) return 'success';
            return 'error';
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: <FormattedMessage id="fail-message" />,
                    variant: 'alert',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    alert: {
                        color: 'error',
                        severity: 'error'
                    }
                })
            );
            return 'error';
        }
    };
}

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconReportSearch, IconFileCheck, IconListDetails } from '@tabler/icons';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import GridViewIcon from '@mui/icons-material/GridView';
import { IoCarSportSharp } from 'react-icons/io5';

const icons = {
    IconDashboard,
    IconReportSearch,
    IconFileCheck,
    IconListDetails,
    BusAlertIcon,
    GridViewIcon,
    IoCarSportSharp
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const superAdmin = {
    id: 'superAdmin',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'vehicle-information',
            title: <FormattedMessage id="vehicle-information" />,
            type: 'item',
            url: '/vehicle-information/vehicles',
            icon: icons.IoCarSportSharp,
            breadcrumbs: false
        },
        {
            id: 'assignment-management',
            title: <FormattedMessage id="assignment-management" />,
            type: 'item',
            url: '/assignment-management/assignments',
            icon: icons.IconReportSearch,
            breadcrumbs: false
        },
        {
            id: 'assignment-requests',
            title: <FormattedMessage id="assignment-requests" />,
            type: 'item',
            url: '/assignment-requests',
            icon: icons.IconFileCheck,
            breadcrumbs: false
        },
        {
            id: 'all-requests',
            title: <FormattedMessage id="all-requests" />,
            type: 'item',
            url: '/all-requests',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'fines',
            title: <FormattedMessage id="fines" />,
            type: 'item',
            url: '/fines',
            icon: icons.BusAlertIcon,
            breadcrumbs: false
        }
    ]
};

export default superAdmin;

import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import App from 'App';
import { store, persister } from 'store';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';

// style + assets
import 'assets/scss/style.scss';
import { AuthProvider } from 'react-oidc-context';
// ==============================|| REACT DOM RENDER  ||============================== //

fetch(`${process.env.PUBLIC_URL}/config.json`)
    .then((res) => res.json())
    .then((data) => {
        window.localStorage.setItem('socket', data.socket);

        ReactDOM.render(
            <AuthProvider {...data}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persister}>
                        <ConfigProvider>
                            <BrowserRouter basename={data.baseHref}>
                                <App />
                            </BrowserRouter>
                        </ConfigProvider>
                    </PersistGate>
                </Provider>
            </AuthProvider>,
            document.getElementById('root')
        );
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    url: ''
};

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setUrl: (state, action) => {
            state.url = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setUrl } = counterSlice.actions;

export default counterSlice.reducer;

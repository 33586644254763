// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, useMediaQuery, CardMedia } from '@mui/material';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import logo from 'assets/images/icons/logo.png';
import { Link } from 'react-router-dom';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid item xs={12}>
            <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                <Grid item>
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                        <Link to="/">
                            <CardMedia component="img" src={logo} width={90} height={90} style={{ objectFit: 'contain' }} />
                        </Link>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Logo;

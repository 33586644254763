import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import { useDispatch, useSelector } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

// assets
import { IconBell } from '@tabler/icons';
import { getNotifications, readAllNotifications } from 'store/slices/notification';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import { getAssignmentRequests } from 'store/slices/assignment';
import { getManagerNewRequests } from 'store/slices/employee';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const { user, socket } = useAuth();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const { notifications } = useSelector((state) => state.notification);
    const [newNotifications, setNewNotifications] = useState([]);
    const currentStyle = window.innerWidth > 600 ? theme.typography.mediumAvatar : theme.typography.smallAvatar;
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleReadAllNotifications = async () => {
        if (newNotifications?.length > 0) {
            await dispatch(readAllNotifications(user?.id));
            await dispatch(getNotifications(user?.id));
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        socket?.on('checkNotification', () => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: <FormattedMessage id="new-notification" />,
                    variant: 'alert',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' }
                })
            );
            dispatch(getNotifications(user?.id));
            if (user.role?.includes('admin') || user.role?.includes('superAdmin')) dispatch(getAssignmentRequests());
            if (user.role?.includes('manager') || user.role?.includes('departmentManager')) dispatch(getManagerNewRequests());
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        setNewNotifications(notifications?.filter((i) => i.hasRead === false));
    }, [notifications]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge badgeContent={newNotifications?.length} color="error" max={10} showZero={false}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    borderColor: theme.palette.primary[200],
                                    background: theme.palette.primary[200],
                                    color: theme.palette.primary.light
                                },
                                marginRight: { xs: '-10px', sm: '' }
                            },
                            ...currentStyle
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="1.3rem" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper elevation={16} sx={{ maxHeight: 400, overflow: 'auto', maxWidth: 330, minWidth: 300 }}>
                                {open && (
                                    <MainCard border={false} content={false} boxShadow shadow={theme.shadows[16]}>
                                        {notifications?.length > 0 ? (
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        sx={{ pt: 2, px: 2 }}
                                                    >
                                                        <Grid item>
                                                            <Stack direction="row">
                                                                <Typography variant="subtitle1">
                                                                    <FormattedMessage id="all-notifications" />
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button variant="text" onClick={handleReadAllNotifications}>
                                                                <FormattedMessage id="mark-all-as-read" />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container direction="column">
                                                        <Grid item xs={12} pb={1}>
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                    <NotificationList items={notifications} setOpen={setOpen} />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        <FormattedMessage id="empty-notifications-list" />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Divider />
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;

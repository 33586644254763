import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'utils/axios';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';

import CarPreLoader from 'ui-component/CarPreLoader/CarPreLoader';
// import { useSelector } from 'store';
// import { useDispatch } from 'react-redux';
import { preloadStatus } from 'store/slices/preloader';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        paddingTop: 40,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            paddingTop: 40
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
            paddingTop: 40
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        paddingTop: 40,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const { status } = useSelector((state) => state.preloader);

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();
    // const preventArray = ['/assignments/fine?filters={', '/file/', '/fines/fineNumber/', '/employees', '/vehicles/plateNumber'];
    const loadStartArray = [
        '/vehicles/export?filters={',
        '/assignments/deliver/',
        '/assignments/return/',
        '/assignments/export?filters={',
        '/fines/export?filters={',
        '/requests/export?filters={',
        '/requests/cancel/',
        '/requests/accept/',
        '/requests/reject/',
        '/fines/pay/'
    ];

    const startWithMethod = [
        { url: '/vehicles', method: 'post' },
        { url: '/vehicles', method: 'put' }
    ];

    const loadEndArray = ['/assignments', 'fines/manual', '/fines', '/requests'];
    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);
    React.useEffect(() => {
        axios.interceptors.request.use(
            (config) => {
                if (
                    loadStartArray.some((item) => config.url.startsWith(item)) ||
                    loadEndArray.some((item) => config.url.endsWith(item)) ||
                    startWithMethod.some((item) => config.url.startsWith(item.url) && config.method === item.method)
                )
                    dispatch(preloadStatus(true));
                return config;
            },
            (error) => Promise.reject(error)
        );

        axios.interceptors.response.use(
            (response) => {
                dispatch(preloadStatus(false));
                return response;
            },
            (error) => {
                dispatch(preloadStatus(false));
                return Promise.reject(error);
            }
        );
    }, []);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {header}
            </AppBar>
            <Sidebar />

            <Main theme={theme} open={drawerOpen}>
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </>
                )}
            </Main>
            {status && <CarPreLoader />}
        </Box>
    );
};

export default MainLayout;

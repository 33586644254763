// routing
import Routes from 'routes';
// project imports
import GlobalStyle from './globalStyle';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
// auth provider
import { JWTProvider } from 'contexts/JWTContext';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <JWTProvider>
                        <GlobalStyle />
                        <Routes />
                        <Snackbar />
                    </JWTProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';
export const DASHBOARD_PATH = '/dashboard';
export const EMPLOYEE_DASHBOARD_PATH = '/employee';
export const MANAGER_DASHBOARD_PATH = '/employee';

const config = {
    fontFamily: `'Cairo', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: navigator.language?.startsWith('en') ? 'en' : 'ar', // 'en' - English, 'ar' - Arabic
    rtlLayout: navigator.language === 'ar' && true,
    container: false
};

export default config;

// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    notifications: [],
    notificationRefresh: 0
};

const slice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Notifications
        getNotificationsSuccess(state, action) {
            state.notifications = action.payload;
        },
        notificationRefresh(state) {
            state.notificationRefresh += 1;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotifications() {
    return async () => {
        try {
            const response = await axios.get('/notifications/employee');
            dispatch(slice.actions.getNotificationsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function notificationRefreshFunc() {
    dispatch(slice.actions.notificationRefresh());
}

export function readNotification(id) {
    return async () => {
        try {
            await axios.get(`/notifications/read/${id}`);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function readAllNotifications() {
    return async () => {
        try {
            await axios.get('/notifications/employee/read');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const prevLocation = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            if (prevLocation?.pathname === '/manager/incoming-requests')
                navigate('login', { state: { from: `${prevLocation?.pathname}` } });
            else navigate('login', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;

// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import cartReducer from './slices/cart';
import menuReducer from './slices/menu';
import vehicleReducer from './slices/vehicle';
import configReducer from './slices/config';
import assignmentReducer from './slices/assignment';
import fineReducer from './slices/fine';
import employeeReducer from './slices/employee';
import notificationReducer from './slices/notification';
import preloaderReducer from './slices/preloader';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    menu: menuReducer,
    vehicle: vehicleReducer,
    config: configReducer,
    assignment: assignmentReducer,
    fine: fineReducer,
    employee: employeeReducer,
    notification: notificationReducer,
    preloader: preloaderReducer
});

export default reducer;

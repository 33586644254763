// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconListDetails } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconListDetails
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const viewer = {
    id: 'viewer',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'all-requests',
            title: <FormattedMessage id="all-requests" />,
            type: 'item',
            url: '/all-requests',
            icon: icons.IconListDetails,
            breadcrumbs: false
        }
    ]
};

export default viewer;

import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function Error() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                background: 'linear-gradient(45deg, #76613b, black)'
            }}
        >
            <Typography variant="h1" style={{ color: 'white', fontSize: '100px' }}>
                404
            </Typography>
            <Typography variant="h3" style={{ color: 'white', margin: '5vh 0 3vh' }}>
                <FormattedMessage id="not-found" />
            </Typography>
            <Button variant="contained" style={{ color: 'white', fontSize: '15px' }}>
                <Link to="/">
                    <FormattedMessage id="Home" />
                </Link>
            </Button>
        </Box>
    );
}

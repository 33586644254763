import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// manager pages
const IncomingRequests = Loadable(lazy(() => import('views/manager-dashboard/IncomingRequests')));
const RequestsRecord = Loadable(lazy(() => import('views/manager-dashboard/RequestsRecord')));
const EmployeeDashboard = Loadable(lazy(() => import('views/employee-dashboard/EmployeeDashboard')));
const RequestDetails = Loadable(lazy(() => import('views/employee-dashboard/EmployeeDashboard/RequestDetails')));
const EmployeeFines = Loadable(lazy(() => import('../views/employee-dashboard/Employeefines')));
const FineDetails = Loadable(lazy(() => import('../views/employee-dashboard/Employeefines/FineDetails')));
const FinesDetails = Loadable(lazy(() => import('views/Fines/FinesDetails')));
// ==============================|| MAIN ROUTING ||============================== //

const ManagerRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <IncomingRequests />
        },
        {
            path: '/manager/incoming-requests',
            element: <IncomingRequests />
        },
        {
            path: '/manager/requests-record',
            element: <RequestsRecord />
        },
        {
            path: '/manager/request-details/:id',
            element: <RequestDetails />
        },
        {
            path: '/employee',
            element: <EmployeeDashboard />
        },
        {
            path: '/employee/request-details/:id',
            element: <RequestDetails />
        },
        {
            path: '/myfines',
            element: <EmployeeFines />
        },
        {
            path: '/myfines/fine-details/:id',
            element: <FineDetails />
        },
        {
            path: '/fines/:id',
            element: <FinesDetails />
        }
    ]
};

export default ManagerRoutes;

import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { io } from 'socket.io-client';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import axios from 'utils/axios';
import { useDispatch } from 'store';
import { getNotifications } from 'store/slices/notification';
import { getAssignmentRequests } from 'store/slices/assignment';
import { getManagerNewRequests, userData } from 'store/slices/employee';
import { useAuth } from 'react-oidc-context';
import Loader from 'ui-component/Loader';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    socket: null
};

// for add or remove token
const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const dispatchAction = useDispatch();
    const auth = useAuth();
    const { socket, user } = state;

    // current user role
    const loginRole = localStorage.getItem('activeRole') ? JSON.parse(localStorage.getItem('activeRole')) : '';

    useEffect(() => {
        socket?.emit('init', user?.id, user?.role[0]);
    }, [socket, user]);

    const logout = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        await dispatch({ type: LOGOUT });
        auth.removeUser();
        setSession(null);
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('activeRole');
        socket?.emit('logout');
    };

    /* OIDC */

    useEffect(() => {
        dispatchAction(userData(false));
        // if user authenticated from oidc or has token
        if (auth.isAuthenticated || window.localStorage.getItem('serviceToken')) {
            dispatchAction(userData(false));

            if (!window.localStorage.getItem('serviceToken')) {
                window.localStorage.setItem('serviceToken', auth.user.access_token);
            }
            axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('serviceToken')}`;
            const roleArray = ['viewer', 'employee', 'manager', 'departmentManager', 'superAdmin', 'admin'];

            (async () => {
                try {
                    const response = await axios.get('/auth');
                    const userRole = response.data.user.role.filter((e) => roleArray.includes(e));
                    response.data.user.role = userRole;

                    const authFun = async (role) => {
                        const socket = io(`${localStorage.getItem('socket')}`);
                        const user = response.data.user;
                        if (role) {
                            user.role = role;
                        }
                        window.localStorage.setItem('user', JSON.stringify(user));
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                socket,
                                user
                            }
                        });

                        await dispatchAction(getNotifications(user?.id));

                        if (user.role?.includes('admin') || user.role.includes('superAdmin')) await dispatchAction(getAssignmentRequests());
                        if (user.role?.includes('manager') || user.role?.includes('departmentManager'))
                            await dispatchAction(getManagerNewRequests());
                        await dispatchAction(userData(false));
                    };

                    if (!loginRole) {
                        authFun(loginRole);
                    } else {
                        localStorage.setItem('activeRole', JSON.stringify(userRole));
                    }
                    await dispatchAction(userData(true));
                } catch (err) {
                    logout();
                    dispatchAction(userData(true));
                }
            })();
        } else {
            logout();

            dispatchAction(userData(true));
        }
    }, [auth.isLoading, auth.isAuthenticated]);

    if (state.isInitialized !== undefined && !state.isInitialized && loginRole.length < 2) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, logout }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconListCheck, IconHistory, IconFileCheck, IconDashboard, IconListDetails } from '@tabler/icons';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';

// constant
const icons = {
    IconListCheck,
    IconHistory,
    IconFileCheck,
    MinorCrashIcon,
    IconDashboard,
    IconListDetails
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const managerDashboard = {
    id: 'managerViewer',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'all-requests',
            title: <FormattedMessage id="all-requests" />,
            type: 'item',
            url: '/all-requests',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'employee',
            title: <FormattedMessage id="my-requests" />,
            type: 'item',
            url: '/employee',
            icon: icons.IconListCheck,
            breadcrumbs: false
        },
        {
            id: 'myfines',
            title: <FormattedMessage id="my-fines" />,
            type: 'item',
            url: '/myfines',
            icon: icons.MinorCrashIcon,
            breadcrumbs: false
        },
        {
            id: 'requests-record',
            title: <FormattedMessage id="requests-record" />,
            type: 'clickableCollapse',
            url: '/manager/requests-record',
            icon: icons.IconHistory,
            breadcrumbs: false,
            children: [
                {
                    id: 'incoming-requests',
                    title: <FormattedMessage id="incoming-requests" />,
                    type: 'item',
                    url: '/manager/incoming-requests',
                    icon: icons.IconFileCheck,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default managerDashboard;

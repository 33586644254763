import { memo } from 'react';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user } = useAuth();

    const navItems = [];
    const navPath = [];
    const navItemPushFun = (itemId) => {
        menuItem.items
            .filter((item) => item.id === itemId)
            // eslint-disable-next-line array-callback-return
            .map((item) => {
                if (item.type === 'group') {
                    navItems.push(...item.children.filter((child) => !navPath.includes(child.url)));
                    navPath.push(...item.children.map((child) => child.url));
                }
            });
        console.log(navItems, itemId);
    };

    if (user?.role?.includes('admin')) {
        navItemPushFun('dashboard');
    }
    if (user?.role?.includes('superAdmin')) {
        navItemPushFun('superAdmin');
    }
    if ((user?.role?.includes('manager') || user?.role?.includes('departmentManager')) && user?.role?.includes('viewer')) {
        navItemPushFun('managerViewer');
    }
    if (user?.role?.includes('manager') || user?.role?.includes('departmentManager')) {
        navItemPushFun('manager-dashboard');
    }
    if (user?.role?.includes('viewer')) {
        navItemPushFun('viewer');
    }
    if (user?.role?.includes('employee')) {
        navItemPushFun('employee-dashboard');
    }
    return <NavGroup item={navItems} />;
};

export default memo(MenuList);

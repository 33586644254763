// project imports
import NotFoundView from '../views/pages/NotFoundView';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '*',
    element: <NotFoundView />,
    children: []
};

export default AuthenticationRoutes;

import dashboard from './dashboard';
import employeeDashboard from './employeeDashboard';
import managerDashboard from './managerDashboard';
import superAdmin from './superAdmin';
import viewer from './viewer';
import managerViewer from './managerViewer';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, employeeDashboard, managerDashboard, superAdmin, viewer, managerViewer]
};

export default menuItems;

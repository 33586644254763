/* eslint-disable prettier/prettier */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';
import { FormattedMessage } from 'react-intl';

// ----------------------------------------------------------------------

const initialState = {
    count: 0,
    error: null,
    employeeRequests: [],
    managerRequests: [],
    managerNewRequests: [],
    request: null,
    requests: [],
    searchResult: [],
    loading: true,
    userData: false,
    managerRequestsCount: 0
};

const slice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // SET LOADING
        changeLoading(state, action) {
            state.loading = action.payload;
        },

        // GET Employee Requests
        getEmployeeRequestsSuccess(state, action) {
            state.employeeRequests = action.payload;
        },
        userData(state, action) {
            state.userData = action.payload;
        },

        // GET Manager New Requests
        getManagerNewRequestsSuccess(state, action) {
            state.managerNewRequests = action.payload;
        },

        // GET Manager Requests
        getManagerRequestsSuccess(state, action) {
            state.managerRequests = action.payload;
        },

        // GET Request
        getRequestSuccess(state, action) {
            state.request = action.payload;
        },

        // GET Request
        getRequestsSuccess(state, action) {
            state.requests = action.payload;
        },

        // GET Request
        getRequestsCountSuccess(state, action) {
            state.count = action.payload;
        },
        // GET Request
        getManagerRequestsCountSuccess(state, action) {
            state.managerRequestsCount = action.payload;
        },

        // MODIFY VEHICLE
        modifyEmployeeRequestSuccess(state, action) {
            const { request } = action.payload;
            state.employeeRequests = [...state.employeeRequests, request];
            console.log('Email Sent');
        },

        // getFilteredRequestSuccess
        getSearchResultSuccess(state, action) {
            state.searchResult = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function userData(status) {
    return async () => {
        dispatch(slice.actions.userData(status));
    };
}

export function getEmployeeRequests() {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));

            const response = await axios.get('/requests/employee');
            dispatch(slice.actions.getEmployeeRequestsSuccess(response.data));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.changeLoading(false));
        }
    };
}

export function getManagerNewRequests() {
    return async () => {
        try {
            const response = await axios.get('/requests/manager/new');
            dispatch(slice.actions.getManagerNewRequestsSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getManagerRequests(filters) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            // const response = await axios.get('/requests/manager');
            const response = await axios.get(`/requests/manager/all?filters=${filters}`);
            dispatch(slice.actions.getManagerRequestsSuccess(response.data.requests));
            dispatch(slice.actions.getManagerRequestsCountSuccess(response.data.count));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.changeLoading(false));
        }
    };
}

export function modifyEmployeeRequests(request, isNewRequest, message) {
    return async () => {
        try {
            if (isNewRequest) {
                const response = await axios.post('/requests', request);
                await dispatch(slice.actions.modifyEmployeeRequestSuccess(response.data));

                await dispatch(
                    openSnackbar({
                        open: true,
                        message,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            } else {
                const response = await axios.put(`/requests/${request.id}`, request);
                dispatch(slice.actions.modifyEmployeeRequestSuccess(response.data));
            }
        } catch (error) {
            if (error.response.data === 'Direct manager not found') {
                await dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="call-it-support" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        alert: {
                            color: 'error',
                            severity: 'error'
                        }
                    })
                );
            }

            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRequest(id) {
    return async () => {
        try {
            const response = await axios.get(`/requests/${id}`);
            dispatch(slice.actions.getRequestSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRequestByToken(token) {
    return async () => {
        try {
            const response = await axios.get(`/requests/token/${token}`);
            dispatch(slice.actions.getRequestSuccess(response.data.request));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function acceptRequest(id, notes, message) {
    return async () => {
        try {
            const response = await axios.put(`/requests/accept/${id}`, { notes });
            await dispatch(
                openSnackbar({
                    open: true,
                    message,
                    variant: 'alert',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' }
                })
            );
            if (response.status === 200) return 'success';
            return 'error';
        } catch (error) {
            console.error(error);
            return 'error';
        }
    };
}

export function rejectRequest(id, notes) {
    return async () => {
        try {
            const response = await axios.put(`/requests/reject/${id}`, { notes });
            if (response.status === 200) return 'success';
            return 'error';
        } catch (error) {
            console.error(error);
            return 'error';
        }
    };
}

export function getAllRequestsCount() {
    return async () => {
        try {
            const response = await axios.get('/requests/count');
            dispatch(slice.actions.getRequestsCountSuccess(response.data.count));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllRequests(page, take) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            const response = await axios.get(`/requests/all/${page}/${take}`);
            dispatch(slice.actions.getRequestsSuccess(response.data));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function searchRequests(filters) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            const response = await axios.get(`/requests/filter?filters=${filters}`);
            dispatch(slice.actions.getRequestsSuccess(response.data.requests));
            dispatch(slice.actions.getRequestsCountSuccess(response.data.count));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.changeLoading(false));
        }
    };
}

export function getAllRequestsEmployeeNumber(number) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            const response = await axios.get(`/requests/search/number/${number}`);
            dispatch(slice.actions.getFilteredRequestSuccess(response.data));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setLoading(value) {
    return async () => {
        dispatch(slice.actions.changeLoading(value));
    };
}

export function filterRequests(status, startDate, endDate) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            const response = await axios.get(`/requests/filter/${status}/${startDate}/${endDate}`);
            dispatch(slice.actions.getSearchResultSuccess(response.data));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.changeLoading(false));
        }
    };
}

// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';
import { FormattedMessage } from 'react-intl';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    assignments: [],
    assignmentRequests: [],
    assignment: null,
    loading: false,
    count: 0,
    update: 0
};

const slice = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Assignments
        getAssignmentsSuccess(state, action) {
            state.assignments = action.payload;
        },

        getAssignmentsCountSuccess(state, action) {
            state.count = action.payload;
        },

        changeLoading(state, action) {
            state.loading = action.payload;
        },

        // GET Assignment
        getAssignmentSuccess(state, action) {
            state.assignment = action.payload;
        },

        // GET Assignment Requests
        getAssignmentRequestsSuccess(state, action) {
            state.assignmentRequests = action.payload;
        },

        // MODIFY Assignment
        modifyAssignmentSuccess(state, action) {
            state.assignments = [...state.assignments, action.payload];
        },
        assignmentUpdate(state) {
            state.update += 1;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAssignments(filters) {
    return async () => {
        try {
            dispatch(slice.actions.changeLoading(true));
            const response = await axios.get(`/assignments/filter?filters=${filters}`);
            dispatch(slice.actions.getAssignmentsSuccess(response.data.assignments));
            dispatch(slice.actions.getAssignmentsCountSuccess(response.data.count));
            dispatch(slice.actions.changeLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAssignment(id) {
    return async () => {
        try {
            const response = await axios.get(`/assignments/${id}`);
            dispatch(slice.actions.getAssignmentSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAssignmentRequests() {
    return async () => {
        const controller = new AbortController();

        try {
            const response = await axios.get('/requests/status/approved', {
                signal: controller.signal
            });
            dispatch(slice.actions.getAssignmentRequestsSuccess(response.data));
        } catch (error) {
            controller.abort();
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function modifyAssignment(assignment, isNewAssignment, assignmentId) {
    let response;
    return async () => {
        try {
            if (isNewAssignment) {
                response = await axios.post('/assignments', assignment);
                dispatch(slice.actions.modifyAssignmentSuccess(response.data));
                dispatch(slice.actions.assignmentUpdate());
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="successful-assignment-add" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            } else {
                response = await axios.put(`/assignments/${assignmentId}`, assignment);
                dispatch(slice.actions.modifyAssignmentSuccess(response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="successful-assignment-edit" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' }
                    })
                );
            }
            if (response.status === 200) return 'success';
            return 'error';
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            if (isNewAssignment) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: <FormattedMessage id="fail-message" />,
                        variant: 'alert',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        alert: {
                            color: 'error',
                            severity: 'error'
                        }
                    })
                );
            }
            return 'error';
        }
    };
}

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
     .css-dhopo2{
        min-height: 200px;
    }
   
`;

export default GlobalStyle;

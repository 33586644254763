import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import EmployeeRoutes from './EmployeeRoutes';
import ManagerRoutes from './ManagerRoutes';
import ViewerRoutes from './ViewerRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import useAuth from 'hooks/useAuth';
import NotFound from './NotFound';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();
    const isManager = user?.role?.includes('manager') || user?.role?.includes('departmentManager');
    const isManagerViewer = isManager && user?.role?.includes('viewer');
    const routerArray = [];

    if (user?.role?.includes('admin') || user?.role?.includes('superAdmin'))
        // eslint-disable-next-line
        routerArray.push(...[MainRoutes]);
    // eslint-disable-next-line
    if (isManagerViewer) routerArray.push(...[ViewerRoutes, ManagerRoutes]);
    // eslint-disable-next-line
    if (isManager) routerArray.push(...[ManagerRoutes]);
    // eslint-disable-next-line
    if (user?.role?.includes('viewer')) routerArray.push(...[ViewerRoutes]);
    // eslint-disable-next-line
    if (user?.role?.includes('employee')) {
        routerArray.push(...[EmployeeRoutes]);
    }
    // eslint-disable-next-line
    if (routerArray.length >= 1) {
        routerArray.push(LoginRoutes, AuthenticationRoutes, NotFound);
        // eslint-disable-next-line
        return useRoutes(routerArray);
    }
    // eslint-disable-next-line

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRoutes([MainRoutes, EmployeeRoutes, ManagerRoutes, LoginRoutes, AuthenticationRoutes, NotFound]);
}
